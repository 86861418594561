import Vue from "vue";
import VueI18n from "vue-i18n";
import deu from "@/translations/de";
import eng from "@/translations/en";

Vue.use(VueI18n);

const messages = {
  deu: deu,
  eng: eng,
};

const i18n = new VueI18n({
  locale: "deu",
  fallbackLocale: "deu",
  messages: messages,
  //silentTranslationWarn: true,
});

export default i18n;
