import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import i18n from "@/plugins/i18n";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#37b6ae",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        appbar_background: "#ffffff",
        appbar_nav_icon: "#757575",
        appbar_help_icon: "#757575",
        appbar_search_background: "#F0F0F0",
        background: "#f7f7f7",
        priority_0: "#4CAF50",
        priority_1: "#FFC107",
        priority_2: "#FF5252",
      },
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});
