<template>
    <v-btn class="test" color="primary" fab dark right fixed elevation="0" v-if="visible" @click="scrollToTop" style="bottom: 80px;">
        <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.toggleBtn);
    },
    methods: {
        toggleBtn() {
            // Show the button if the scroll height is greater than the screen height
            this.visible = window.pageYOffset >= screen.height;
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
    }
}
</script>