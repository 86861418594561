import { http } from "@/plugins/axios";

class License {
    constructor() {
        this.id = null;
        this.name = "";
        this.number_users = 0;
        this.number_companies = 0;
        this.remaining_number_users = 0;
        this.remaining_number_companies = 0;
        this.permissions = [];
    }

    static retrieve(company_id) {
        return http.get("companies/" + company_id + "/license/")
    }
}

export { License };