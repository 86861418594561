import get from 'lodash/get';
import i18n from '@/plugins/i18n'
import { getPersonData } from '@/classes/User';
import { calculate_scenario } from "@/components/charts/risk-chart/helpers";

function format_array_to_string(arr, key = "name", version = false, info_keys = []) {
  let str = "";

  arr.forEach((elem, index) => {
    if (index > 0)
      str += ", "

    str += get(elem, key)

    if (version) {
      // Generate info object
      let info = {}

      info_keys.forEach((info_elem) => {
        if (Object.hasOwn(elem, info_elem.key)) {
          info[info_elem.name] = elem[info_elem.key]
        }
      })

      str += extra_info(info)
    }
  });

  return str;
}

function extra_info(infos) {
  let text = "";
  const length = Object.keys(infos).length;
  let index = 0;

  for (let key in infos) {
    text += key + ": " + infos[key] + (index + 1 < length ? ", " : "");
    index++;
  }

  return " <span class='version-extra-info'>(" + text + ")</span>";
}

function generate_list(elements, version = false, info_keys = []) {
  let list_template = "<ul>%text%</ul>";

  let list_elements = "";

  elements.forEach((elem) => {
    if (version) {
      // Generate info object
      let info = {}

      info_keys.forEach((info_elem) => {
        if (Object.hasOwn(elem, info_elem.key)) {
          info[info_elem.name] = elem[info_elem.key]
        }
      })

      list_elements += "<li>" + elem.name + extra_info(info) + "</li>";
    } else {
      list_elements += "<li>" + elem.name + "</li>";
    }
  });

  if (list_elements != "") {
    return list_template.replace("%text%", list_elements);
  } else {
    return null;
  }
}

function generate_question_list(elements) {
  let list_elements = "";
  let number = 1;

  elements.forEach((elem) => {
    list_elements += "<div class='completeQuestion'> <div class='questionNameAndSwitch'> <div class='questionName'>" + number + "." + " " + elem.name + "</div>"
    if (elem.answer == false) {
      elem.answer = "Nein";
      list_elements += "<div class='questionSwitchNo'>" + elem.answer + "</div> </div>"

    } else {
      elem.answer = "Ja";
      list_elements += "<div class='questionSwitchYes'>" + elem.answer + "</div> </div>"
    }

    if (elem.description != "") {
      list_elements += "<div class='questionDesciption'>" + elem.description + "</div>";
    }
    list_elements += "</div>"
    list_elements += "<hr>"
    number += 1;
  });
  return list_elements;
}

function getRisk(risk) {
  if (risk < 1) {
    return { color: "green", value: 1 }
  }
  if (risk <= 2) {
    return { color: "yellow", value: 2 }
  }

  if (risk <= 3) {
    return { color: "orange", value: 3 }
  }

  return { color: "red", value: 4 }
}

function generateSources(sources) {
  let list_elements = "";

  sources.forEach((source) => {
    list_elements += "<div class='completeSource'> " + "<div class='name' style='font-weight: 600'>" + source.name + "</div>"

    if (source.source_description != "" || source.damage_description != "") {
      list_elements += "<div class='reasonAndDamageDesc'>";

      if (source.source_description != "") {
        list_elements += "<div class='reasonDes'>" + i18n.t("begruendungUndUrsacheDerRisikoquelle") + "</div><div><li>" + source.source_description + "</li></div>"
      }
      if (source.damage_description != "") {
        list_elements += "<div class='damageDes'>" + i18n.t("potenzielleSchaeden") + "</div><div><li>" + source.damage_description + "</li></div>"
      }
      list_elements += "</div>"
    }
    list_elements += "</div>"
    list_elements += "<hr>"
  })
  return list_elements;
}

function check_third_country(data) {
  if (data.external_receiver.length == 0 && !data.data_transfer_third_country) {
    return i18n.t("kein_drittland");
  }


  let third_countries = 0;
  let secure_third_countries = 0;

  data.external_receiver.forEach((obj) => {
    // Check external receiver location
    if (!obj.external_data_receiver.location.country.eea) {
      if (obj.external_data_receiver.location.country.secure_third_country) {
        secure_third_countries++;
      } else {
        third_countries++;
      }
    }

    // Check all storage locations
    obj.storage_locations.forEach((location) => {
      if (!location.location.eea) {
        if (location.location.secure_third_country) {
          secure_third_countries++;
        } else {
          third_countries++;
        }
      }
    });
  });

  if (third_countries > 0 || data.data_transfer_third_country) {
    return i18n.t("unsicher_drittland");
  }

  if (secure_third_countries > 0) {
    return i18n.t("sicher_drittland");
  }

  return i18n.t("kein_drittland");
}

function generate_erasure_time_limits(data, version = false) {
  const erasure_time_limits = [];

  data.categories_personal_data.forEach((obj) => {
    if (!(obj.deletion_period.length == 0 && obj.repositories.length == 0 && obj.description == "")) {
      if (version) {
        erasure_time_limits.push({ name: obj.name, deletion_period: (obj.deletion_period != null ? (obj.deletion_period.period + " " + i18n.t(obj.deletion_period.entity) + extra_info({ id: obj.deletion_period.id })) : "-"), repositories: obj.repositories.length == 0 ? "-" : format_array_to_string(obj.repositories, "name", version, [{ key: "id", name: "id" }]), description: obj.description })
      } else {
        let periods = "";

        obj.deletion_period.forEach((item, index) => {
          if (index > 0)
            periods += ", "
          if (item.period == 1) {
            periods += item.period + " " + i18n.t(item.entity + "_single");
          } else {
            periods += item.period + " " + i18n.t(item.entity);
          }
        });

        erasure_time_limits.push({ name: obj.name, deletion_period: obj.deletion_period.length == 0 ? "-" : periods, repositories: obj.repositories.length == 0 ? "-" : format_array_to_string(obj.repositories), description: obj.description })
      }
    }
  });

  data.special_categories_personal_data.forEach((obj) => {
    if (!(obj.deletion_period.length == 0 && obj.repositories.length == 0 && obj.description == "")) {
      if (version) {
        erasure_time_limits.push({ name: obj.name, deletion_period: (obj.deletion_period != null ? (obj.deletion_period.period + " " + i18n.t(obj.deletion_period.entity) + extra_info({ id: obj.deletion_period.id })) : "-"), repositories: obj.repositories.length == 0 ? "-" : format_array_to_string(obj.repositories, "name", version, [{ key: "id", name: "id" }]), description: obj.description })
      } else {
        let periods = "";

        obj.deletion_period.forEach((item, index) => {
          if (index > 0)
            periods += ", "

          if (item.period == 1) {
            periods += item.period + " " + i18n.t(item.entity + "_single");
          } else {
            periods += item.period + " " + i18n.t(item.entity);
          }
        });

        erasure_time_limits.push({ name: obj.name, deletion_period: obj.deletion_period.length == 0 ? "-" : periods, repositories: obj.repositories.length == 0 ? "-" : format_array_to_string(obj.repositories), description: obj.description })
      }
    }
  });

  return erasure_time_limits;
}

/**
 * 
 * @param { Object } processing_activity An object that include (a piece of) the processing activity data structure
 * @param { Array } exclude An array that controll which paragraph should includes in the generated output. One or more of the following words: name, company, controller, third_country, erasure_concept, measures
 * @returns A string representation in html of the given processing activity
 */
function generateProcessingActivityHTML(processing_activity, exclude = [], version = false) {
  let html = "";

  const heading_template = "<div class='text-h4' id='%id%'>%text%</div>";
  const heading_2_template = "<div class='text-h5'>%text%</div>";
  const heading_3_template = "<div class='text-h6'>%text%</div>";
  const heading_receiver_template = "<a class='receiver text-h6' data-id='%id%'>%text%</a>";
  const text_template = "<p>%text%</p>";

  // Titel
  if (!exclude.includes('name')) {
    html += "<div class='text-h3'>" + processing_activity.name + "</div>";
  }

  // Verantwortlicher
  if (processing_activity.company != null && processing_activity.company != undefined && !exclude.includes('company')) {
    html += heading_template.replace("%text%", i18n.t("verantwortlicher")).replace("%id%", "company");
    html += text_template.replace("%text%", processing_activity.company.name);
    html += text_template.replace("%text%", processing_activity.company.street + " " + processing_activity.company.house_number);

    if (version) {
      html += text_template.replace("%text%", processing_activity.company.location.postcode + " " + processing_activity.company.location.name + extra_info({ id: processing_activity.company.location.id }));
    } else {
      html += text_template.replace("%text%", processing_activity.company.location.postcode + " " + processing_activity.company.location.name);
    }
  }

  // Vertreter / Ansprechpartner
  if (processing_activity.controller != null && processing_activity.controller != undefined && !exclude.includes('controller')) {
    html += heading_template.replace("%text%", i18n.t("ansprechpartner")).replace("%id%", "controller");

    if (version) {
      html += text_template.replace("%text%", processing_activity.controller.forename + " " + processing_activity.controller.surname + extra_info({ id: processing_activity.company.location.id }));
    } else {
      html += text_template.replace("%text%", processing_activity.controller.forename + " " + processing_activity.controller.surname);
    }

    if (processing_activity.controller.phone != null) {
      html += text_template.replace("%text%", processing_activity.controller.phone);
    }
  }

  // Datenschutzbeauftragter
  if (processing_activity.company != null && processing_activity.company != undefined && !exclude.includes('company')) {
    html += heading_template.replace("%text%", i18n.t("dsb")).replace("%id%", "privacy_officer");

    if (version) {
      html += text_template.replace("%text%", processing_activity.company.privacy_officer.forename + " " + processing_activity.company.privacy_officer.surname + extra_info({ id: processing_activity.company.location.id }));
    } else {
      html += text_template.replace("%text%", processing_activity.company.privacy_officer.forename + " " + processing_activity.company.privacy_officer.surname);
    }

    if (processing_activity.company.privacy_officer.phone != null) {
      html += text_template.replace("%text%", processing_activity.company.privacy_officer.phone);
    }
  }

  html += "<hr>"

  // Beschreibung
  html += heading_template.replace("%text%", i18n.t("beschreibung")).replace("%id%", "description");
  html += text_template.replace("%text%", processing_activity.description);

  html += "<hr>"

  // Zweck der Verarbeitung
  html += heading_template.replace("%text%", i18n.t("zwecke_der_Verarbeitung")).replace("%id%", "purposes");
  let list = generate_list(processing_activity.purposes, version, [{ key: "id", name: "id" }]);
  if (list != null) {
    html += list;
  }
  if (processing_activity.purpose_description != "") {
    html += text_template.replace("%text%", processing_activity.purpose_description);
  }

  html += "<hr>"

  // Rechtsgrundlagen
  html += heading_template.replace("%text%", i18n.t("rechtsgrundlagen")).replace("%id%", "legal_basis");
  list = generate_list(processing_activity.legal_basis, version, [{ key: "id", name: "id" }]);
  if (list != null) {
    html += list;
  }
  if (processing_activity.legal_basis_description != "") {
    html += text_template.replace("%text%", processing_activity.legal_basis_description);
  }

  html += "<hr>"

  // Personenbezogene Daten
  html += heading_template.replace("%text%", i18n.t("kategorien_personenbezogener_Daten")).replace("%id%", "categories_personal_data");
  list = generate_list(processing_activity.categories_personal_data, version, [{ key: "id", name: "id" }, { key: "risk_s", name: "Schaden" }]);
  if (list != null) {
    html += list;
  } else {
    html += "<p>-</p>"
  }

  html += "<hr>"

  // Besondere Kategorien
  html += heading_template.replace("%text%", i18n.t("besondere_kategorien_personenbezogener_Daten")).replace("%id%", "special_categories_personal_data");
  list = generate_list(processing_activity.special_categories_personal_data, version, [{ key: "id", name: "id" }, { key: "risk_s", name: "Schaden" }]);
  if (list != null) {
    html += list;
  } else {
    html += "<p>-</p>"
  }

  html += "<hr>"

  // Betroffene
  html += heading_template.replace("%text%", i18n.t("kategorien_der_betroffenen")).replace("%id%", "categories_data_subject");
  list = generate_list(processing_activity.categories_data_subject, version, [{ key: "id", name: "id" }, { key: "risk_s", name: "Schaden" }]);
  if (list != null) {
    html += list;
  } else {
    html += "<p>-</p>"
  }

  html += "<hr>"

  // Empfänger
  html += heading_template.replace("%text%", i18n.t("kategorien_der_empfaenger")).replace("%id%", "receiver");
  // Kategorien
  list = generate_list(processing_activity.categories_receiver, version, [{ key: "id", name: "id" }]);
  if (list != null) {
    html += list;
  }

  // Abteilungen
  html += heading_2_template.replace("%text%", i18n.t("interne_empfaenger"));

  list = generate_list(processing_activity.internal_receiver, version, [{ key: "id", name: "id" }]);

  if (list != null) {
    html += list;
  } else {
    html += "<p>-</p>"
  }
  // Externe Empfänger
  html += heading_2_template.replace("%text%", i18n.t("externe_empfaenger"));
  if (processing_activity.external_receiver.length <= 0) {
    html += "<p>-</p>"
  } else {
    processing_activity.external_receiver.forEach((receiver) => {
      if (version) {
        html += heading_receiver_template.replace("%text%", receiver.external_data_receiver.name + extra_info({ id: receiver.external_data_receiver.id })).replace("%id%", receiver.external_data_receiver.id);
      } else {
        html += heading_receiver_template.replace("%text%", receiver.external_data_receiver.name).replace("%id%", receiver.external_data_receiver.id);
      }

      html += "<dl><dt>" + i18n.t("orte_der_Verarbeitung") + "</dt><dd>";

      receiver.storage_locations.forEach(location => {
        html += location.location.name;

        html += " (" + format_array_to_string(location.appropriate_safeguards, "name", version, [{ key: "id", name: "id" }]) + ")";
      })


      html += "</dd><dt>" + i18n.t("beschreibung") + "</dt><dd>" + (receiver.description != "" ? receiver.description : "-") + "</dd></dl>";
    });
  }

  // Drittland
  if (!exclude.includes('third_country')) {
    html += heading_template.replace("%text%", i18n.t("datenuebertragung_in_drittlaender")).replace("%id%", "third_country");
    html += text_template.replace("%text%", check_third_country(processing_activity));

    html += "<hr>"
  }

  // Löschkonzept
  if (!exclude.includes('erasure_concept')) {
    html += heading_template.replace("%text%", i18n.t("loeschfristen_und_loeschkonzept")).replace("%id%", "erasure_time_limits");
    html += "<p>" + i18n.t("erasure_concept_hint") + "</p>"

    generate_erasure_time_limits(processing_activity, version).forEach((item) => {
      html += heading_3_template.replace("%text%", item.name);
      html += "<dl><dt>" + i18n.t("aufbewahrungsorte") + "</dt><dd>" + item.repositories + "</dd><dt>" + i18n.t("loeschfrist") + "</dt><dd>" + item.deletion_period + "</dd><dt>" + i18n.t("beschreibung") + "</dt><dd>" + (item.description != "" ? item.description : "-") + "</dd></dl>";
    });

    html += "<hr>"
  }

  // Maßnahmen
  if (!exclude.includes('measures')) {
    html += heading_template.replace("%text%", i18n.t("tom")).replace("%id%", "measures");

    if (processing_activity.measures != undefined) {
      list = generate_list(processing_activity.measures, version, [{ key: "id", name: "id" }, { key: "risk_s", name: "Schaden" }, { key: "risk_p", name: "Eintritt" }]);
      if (list != null) {
        html += list;
      }
    }

    html += "<p>" + i18n.t("tom_hinweis") + "</p>"
  }

  return html;
}

/**
 * 
 * @param { Object } impact_assessment An object that include (a piece of) the impact assessment data structure
 * @param { Array } exclude An array that controll which paragraph should includes in the generated output. One or more of the following words: name, company, controller, third_country, erasure_concept, measures
 * @returns A string representation in html of the given processing activity
 */

function generateImpactAssessmentHTML(impact_assessment) {
  let html = "";

  const heading_template = "<div class='text-h4' id='%id%'>%text%</div>";
  const heading_2_template = "<div class='text-h5'>%text%</div>";
  //const heading_3_template = "<div class='text-h6'>%text%</div>";
  //const heading_receiver_template = "<a class='receiver text-h6' data-id='%id%'>%text%</a>";
  //const text_template = "<p>%text%</p>";

  // Titel
  //html += "<div class='text-h3'>" + impact_assessment.name + "</div>";

  // Verantwortlicher
  // Vertreter 
  // Ansprechpartner
  // Datenschutzbeauftragter
  // Beschreibung
  // Zweck der Verarbeitung
  // Rechtsgrundlagen
  // Personenbezogene Daten
  // Besondere Kategorien
  // Betroffene
  // Empfänger
  // Abteilungen
  // Externe Empfänger
  // Drittland
  // Löschkonzept
  // Maßnahmen
  const dataFromProcessingActivity = generateProcessingActivityHTML(impact_assessment.processing_activity, ["measures"]);
  html += dataFromProcessingActivity

  //Gesetzliche Fragen
  html += heading_template.replace("%text%", i18n.t("gesetzlicheFragen")).replace("%id%", "lawfully_questions");
  let lawfullyList = generate_question_list(impact_assessment.legal_questions);
  html += lawfullyList;

  html += "<hr>"

  //Weitere Risikofragen
  html += heading_template.replace("%text%", i18n.t("weitereFragen")).replace("%id%", "additional_questions");
  let additionalList = generate_question_list(impact_assessment.additional_questions);
  html += additionalList;

  html += "<hr>"

  //Risikoanalyse
  html += heading_template.replace("%text%", i18n.t("risikoanalyse")).replace("%id%", "risks");
  if (impact_assessment.scenarios.length <= 0) {
    html += "<p>-</p>"
  } else {
    impact_assessment.scenarios.forEach((scenario) => {
      html += heading_2_template.replace("%text%", scenario.name);
      let resoucesList = generateSources(scenario.sources)
      let measuresList = format_array_to_string(scenario.measures)

      const risk = calculate_scenario(scenario);

      html += "</dl><dt>" + i18n.t("beschreibung") + "</dt><dd>" + (scenario.description != "" ? scenario.description : "-") + "</dd><dt>" + i18n.t("risikoquelle") + "</dt><dd>" + resoucesList + "</dd><dt>" + i18n.t("tom") + "</dt><dd>" + measuresList + "</dd></dl>";
      html += "</dd><dt>" + i18n.t("brutto_risk_probability") + "</dt><dd>" + i18n.t('risk_' + getRisk(risk.gross.p).value)
      html += "</dd><dt>" + i18n.t("brutto_risk_severity") + "</dt><dd>" + i18n.t('risk_' + getRisk(risk.gross.s).value)

      html += "</dd><dt>" + i18n.t("netto_risk_probability") + "</dt><dd>" + i18n.t('risk_' + getRisk(risk.net.p).value)
      html += "</dd><dt>" + i18n.t("netto_risk_severity") + "</dt><dd>" + i18n.t('risk_' + getRisk(risk.gross.s).value)

    })
  }

  html += "<hr>"


  return html;
}

function get_substring(str, length) {
  if (str.length > length) {
    return str.slice(0, length) + " ..."
  } else {
    return str;
  }
}

function formatDate(date) {
  if (date != null)
    return new Date(date).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' })

  return null;
}

function formatTime(date) {
  if (date != null)
    return new Date(date).toLocaleTimeString("de-DE", { hour: '2-digit', minute: '2-digit' })

  return null;
}


function persons_to_string(persons) {
  let str = "";

  if (persons.length <= 0) {
    return "-"
  }

  persons.forEach((person, index) => {
    if (index > 0)
      str += ", "

    if (person.type == 'user' && (person.forename == '' || person.surname == '')) {
      str += person.email;
    } else {
      str += person.forename + " " + person.surname;
    }
  });

  return str;
}

function get_time_to_text(period_obj, prepend_text = "", append_text = "") {
  let result = prepend_text;

  if (period_obj.years > 0) {
    if (period_obj.years == 1) {
      result += " " + period_obj.years + " " + i18n.t('jahr')
    } else {
      result += " " + period_obj.years + " " + i18n.t('jahre')
    }
  }

  if (period_obj.months > 0) {
    if (period_obj.months == 1) {
      result += " " + period_obj.months + " " + i18n.t('monat')
    } else {
      result += " " + period_obj.months + " " + i18n.t('monate')
    }
  }

  if (period_obj.weeks > 0) {
    if (period_obj.weeks == 1) {
      result += " " + period_obj.weeks + " " + i18n.t('woche')
    } else {
      result += " " + period_obj.weeks + " " + i18n.t('wochen')
    }
  }

  if (period_obj.days > 0) {
    if (period_obj.days == 1) {
      result += " " + period_obj.days + " " + i18n.t('tag')
    } else {
      result += " " + period_obj.days + " " + i18n.t('tage')
    }
  }

  result += append_text;

  return result
}


// From: https://stackoverflow.com/a/5624139/11988593
function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function validateDeadline(deadline) {
  if (!deadline) return false;
  const keys = ['days', 'months', 'weeks', 'years'];
  return keys.some((key) => deadline[key] && (deadline[key] > 0));
}

function get_period(item) {
  let text = "";

  if (item['years'] > 0) {
    text += item['years'] + " Jahre"
  }

  if (item['months'] > 0) {
    if (text != "") text += ", ";
    text += item['months'] + " Monate"
  }

  if (item['weeks'] > 0) {
    if (text != "") text += ", ";
    text += item['weeks'] + " Wochen"
  }

  if (item['days'] > 0) {
    if (text != "") text += ", ";
    text += item['days'] + " Tage"
  }

  return text;
}

function get_time_limits(items) {
  let output = "";

  items.forEach((item, index) => {
    if (index > 0)
      output += ", "

    output += item.period + " " + this.$t(item.entity + (item.period == 1 ? "_single" : ""));
  });

  return output;
}

function get_persons(persons) {
  let result = "";

  if (Array.isArray(persons) && persons.length <= 0) return "-"

  for (let i = 0; i < persons.length; i++) {
    result += getPersonData(persons[i]);
    if (i + 1 < persons.length) {
      result += ", "
    }
  }

  return result;
}

export { validateDeadline, get_time_limits, get_period, calculate_scenario, get_persons, get_time_to_text, hexToRgb, persons_to_string, generate_erasure_time_limits, check_third_country, format_array_to_string, get_substring, formatTime, formatDate, generateProcessingActivityHTML, generateImpactAssessmentHTML, getRisk }
