import Router from "@/router/index";
import { http, baseURL } from "@/plugins/axios";
import i18n from '@/plugins/i18n'

class File {
    static permissions = [
        {
            name: i18n.t('erstellen_gross'),
            perm: "files.add_file",
        },
        {
            name: i18n.t('herunterladen'),
            perm: "files.download_file",
        },
        {
            name: i18n.t('loeschen'),
            perm: "files.delete_file",
        },
    ]

    static async files(id, name = null, params = {}) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        if (typeof params !== "object") {
            params = {}
        }

        if (name != null) {
            params["name"] = name;
        }

        return http.get("companies/" + company_id + this.url + id + "/files/", {
            params: params,
        });
    }

    static async upload(id, files) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        let formData = new FormData();

        for (var i = 0; i < files.length; i++) {
            formData.append('file', files[i].file);
        }

        return http.post("companies/" + company_id + this.url + id + "/files/", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        });
    }

    static async remove(id) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }

        return http.delete("companies/" + company_id + "/files/" + id + "/");
    }

    static async download(file) {
        return http.get(baseURL + file.url, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data])
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.setAttribute('download', file.name)
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
    }
}

export { File };
