import { http } from "@/plugins/axios";

class Notification {
    static async list(params = {}) {
        if (typeof params !== "object") {
            params = {}
        }

        return http.get("notifications/", {
            params: params,
        });
    }
}

export { Notification }
