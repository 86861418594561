import { http } from "@/plugins/axios";
import Router from "@/router/index";
import { list } from "@/classes/helpers";
import i18n from '@/plugins/i18n'
import { useGlobalStore } from '@/store/globalStore';

class Company {
  static permissions = [
    {
      name: i18n.t('tochter_erstellen'),
      perm: "company.add_company",
    },
    {
      name: i18n.t('bearbeiten'),
      perm: "company.change_company",
    },
  ]

  static getVariables(company = null) {
    if (company == null) {
      return [
        { unternehmen_name: "unternehmen_name" },
        { unternehmen_strasse: "unternehmen_strasse" },
        { unternehmen_hausnummer: "unternehmen_hausnummer" },
        { unternehmen_ort: "unternehmen_ort" },
        { unternehmen_plz: "unternehmen_plz" },
      ]
    } else {
      return [
        { unternehmen_name: company.name },
        { unternehmen_strasse: company.street },
        { unternehmen_hausnummer: company.house_number },
        { unternehmen_ort: company.location.name },
        { unternehmen_plz: company.location.postcode },
      ]
    }
  }

  constructor(
    data = {
      name: "",
      street: "",
      house_number: "",
      location: null,
      privacy_officer: null,
      coordinator: null,
      parentId: null,
      disabled: true,
      supervisory_authority: null,
      annual_revenue: null,
      resource_labels: [],
    }
  ) {
    this.id = null;
    this.name = data.name;
    this.street = data.street;
    this.house_number = data.house_number;
    this.location = data.location;
    this.privacy_officer = data.privacy_officer;
    this.coordinator = data.coordinator;
    this.parentId = data.parentId;
    this.disabled = data.disabled;
    this.supervisory_authority = data.supervisory_authority;
    this.annual_revenue = null;
    this.resource_labels = data.resource_labels;
  }

  static async list(name = null, params = {}) {
    return list("companies/", name, params, false);
  }

  static structure(full = false) {
    const company_id = Router.currentRoute.params?.companyId;

    if (company_id == undefined) {
      return;
    }

    return http.get("companies/" + company_id + "/structure/", { params: { full: full } });
  }

  static concern() {
    const company_id = Router.currentRoute.params?.companyId;

    if (company_id == undefined) {
      return;
    }

    return http.get("companies/" + company_id + "/concern/");
  }

  static retrieve() {
    const company_id = Router.currentRoute.params?.companyId;

    if (company_id == undefined) {
      return;
    }

    return http.get("companies/" + company_id + "/");
  }

  static create_subsidiary(data) {
    const company_id = Router.currentRoute.params?.companyId;

    if (company_id == undefined) {
      return;
    }
    // Flatten data
    data.location = data.location.id;
    data.supervisory_authority = data.supervisory_authority ? data.supervisory_authority.id : null;
    data.annual_revenue = parseInt(data.annual_revenue) > 0 ? data.annual_revenue : null;

    return http.post("companies/" + company_id + "/subsidiary/", data);
  }

  static async update(data) {
    const company_id = Router.currentRoute.params?.companyId;

    if (company_id == undefined) {
      return;
    }

    // Flatten data
    data.location = data.location.id;
    data.coordinator = data.coordinator.id;
    data.privacy_officer = data.privacy_officer.id;
    data.supervisory_authority = data.supervisory_authority ? data.supervisory_authority.id : null;
    data.annual_revenue = parseInt(data.annual_revenue) > 0 ? data.annual_revenue : null;


    return http.patch("companies/" + company_id + "/", data);
  }

/*   static async updateResourceLabels(data, ) {
    const company_id = Router.currentRoute.params?.companyId;

    if (company_id == undefined) {
      return;
    }
    // flatten resource labels
    if (data.params && Array.isArray(data.resource_labels)) {
        data.resource_labels = data.resource_labels.map(label => label.id);
    }

    if (typeof data.location === 'object' && data.location !== null) {
        data.location = data.location.id;
    }
    
    if (typeof data.coordinator === 'object' && data.coordinator !== null) {
        data.coordinator = data.coordinator.id;
    }
    
    if (typeof data.privacy_officer === 'object' && data.privacy_officer !== null) {
        data.privacy_officer = data.privacy_officer.id;
    }
  
    return http.patch("companies/" + company_id + "/", data);
  } */
}

class Concern {
  static async list(name = null, params = {}) {
    return list("concern/", name, params)
  }
}

function has_license_perm(code = '') {
  const globalStore = useGlobalStore();

  if (!Array.isArray(globalStore.get_license.permissions))
    return false;

  return globalStore.get_license.permissions.includes(code);
}

function get_license() {
  const globalStore = useGlobalStore();
  return globalStore.get_license;
}

export { Company, Concern, has_license_perm, get_license };
